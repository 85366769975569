const config = {
  env: "local",

  // apiUrl: "http://localhost:5522/admin/",
  apiUrl:"https://api.missionment.com/admin/",
  // apiUrl: "https://api.realtyavenue.in/admin",

  shipRocketApiUrl: "https://apiv2.shiprocket.in/v1/external/",
};

export default config;
